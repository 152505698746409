import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image';


const Instagram = () => {
  const data = useStaticQuery(graphql`
  query {
    allInstaNode(
      limit:6,
      sort: {
        fields: [timestamp]
        order: [DESC]
      }
    ) {
      edges {
        node {
          id
          likes
          comments
          mediaType
          preview
          original
          timestamp
          localFile {
            childImageSharp {
              fluid(maxWidth: 300) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
  `)
  const instaPosts = data.allInstaNode.edges;
  return (
    <div className="josefin flex flex-col">
      <a href="https://www.instagram.com/halsoffice/" target="_blank" rel="noreferrer noopener" >
      <h2 className='text-4xl text-gray-900'>INSTAGRAM</h2>
      <span className='text-blue-500'>@halsoffice</span>
      </a>
    
      <div className="grid lg:grid-cols-3 lg:grid-rows-2 grid-cols-2 grid-rows-3 grid-flow-col lg:gap-4 gap-2 lg:w-3/4 w-full mx-auto">
          {instaPosts.map(({ node }) => {
            return (
              <div key={node.id} className="">
                {console.log(node.id)}
                <a href={`https://www.instagram.com/halsoffice/p/${node.id}`} target="_blank" rel="noreferrer noopener"> 
                <Img className='lg:mx-4 lg:h-64 h-32' fluid={node.localFile.childImageSharp.fluid} />

                </a>
               
              </div>
            )
          })}
      </div>
    </div>
  )
}

export default Instagram;