import React from 'react'
import {useStaticQuery, graphql} from 'gatsby'

const Retail = () => {
  const data = useStaticQuery(graphql`
  query {
    allContentfulMenuItem(
      filter: {
        category: {
          title: {eq: "Food"}
        }
      }
      sort: { fields: title, order: ASC }
    ) {
      edges {
        node {
          title
          id
          price
          category{
            title
          }
        }
      }
    }
  }
  `)

  const items = data.allContentfulMenuItem.edges;
  return (
    <div className='lg:w-3/4 w-full'>
      <ul className="my-2 grid grid-cols-1 gap-4">
        {
          items.map(({ node }) => {
            return (
              <li key={node.id} className="menu-item">
                <h3 className='font-bold text-lg'>{node.title}</h3>
                <span>${node.price}</span>
              </li>
            )
          })
        }
      </ul>
    </div>
  )
}

export default Retail;