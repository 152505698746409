import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Menu from "../components/menu/menu"
import Instagram from "../components/instagram"
import Footer from "../components/footer"
import "../styles/style.css"
import Logo from "../images/logo.svg"
import { useSpring, animated } from 'react-spring';


const IndexPage = ({ data }) => {
  const animationProps = useSpring({
    opacity: 1,
    marginTop: 0,
    from: { opacity: 0, marginTop: 100 }
  });
  return (
    <Layout>
      <SEO title="Home" />
      <animated.div className='' style={animationProps}>
      <section className="relative">
        <div className='lg:h-screen overflow-hidden'>
        <Img fluid={data.coffeeShop.childImageSharp.fluid} className="w-full h-auto" />
        <div className='absolute w-full top-0'>
        <img src={Logo} className='lg:w-1/2 w-3/4 mx-auto overflow-hidden' alt='logo'/>

        </div>
        </div>
       
      </section>
      <section className="">
        <div className="lg:mx-32 mx-12 flex flex-col lg:flex-row lg:inline-flex justify-between py-12 josefin text-gray-900">
          <div className="lg:text-6xl text-4xl lg:pr-12 leading-tight lg:mb-0 mb-4 lg:w-1/2"> Stylish yet cozy digs.</div>
          <div className="lg:text-2xl text-lg lg:w-1/2">
            Hal's Office is a locally owned business and landmark of Solano
            avenue in Albany. <span className='inline-block'>Our purpose is to offer a coffee experience that
            celebrates the finer craft of artisan coffee and preserve the
            generational charm of Albany's community.</span>
          </div>
        </div>

        <div className="grid grid-rows-2 grid-flow-col lg:gap-4 gap-2 lg:mx-32 mx-12">
          <Img
            fluid={data.pull.childImageSharp.fluid}
            className="row-span-2 col-span-3"
          />
          <Img
            fluid={data.cappucino.childImageSharp.fluid}
            className="row-span-1 col-span-2"
          />
          <Img
            fluid={data.lattePour.childImageSharp.fluid}
            className="row-span-1 col-span-2"
          />
        </div>
      </section>

      <section className="lg:mx-32 mx-12">
        <section className='flex lg:flex-row flex-col py-12'>
          <Menu />
          <Img className='lg:w-1/2 sm:w-3/4 w-full h-full mx-auto' fluid={data.illustration.childImageSharp.fluid} />
        </section>
        <Instagram />
      </section>
      <Footer />
      </animated.div>
    </Layout>
  )
}

export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(maxWidth: 1600) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export const pageQuery = graphql`
  query {
    coffeeShop: file(relativePath: { eq: "cafe.jpg" }) {
      ...fluidImage
    }
    cappucino: file(relativePath: { eq: "cappucino.jpg" }) {
      ...fluidImage
    }
    lattePour: file(relativePath: { eq: "lattePour.jpeg" }) {
      ...fluidImage
    }
    pull: file(relativePath: { eq: "pull.jpeg" }) {
      ...fluidImage
    }
    illustration: file(relativePath: { eq: "illustration.png" }) {
      ...fluidImage
    }
  }
`

export default IndexPage
