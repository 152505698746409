import React from 'react'

const Footer = () => {

  return (
    <footer className='josefin text-gray-900 mx-12'>
      <section className='flex lg:flex-row flex-col justify-around py-16'>
        <div className="flex flex-col">
          <h3 className='font-bold text-lg pt-4 lg:pb-4'>LOCATED:</h3>
          <span className='font-light'>1207 Solano Ave</span>
          <span>Albany, CA 94706</span>
        </div>
        <div className="">
          <h3 className='font-bold text-lg pt-4 lg:pb-4'>HOURS:</h3>
          <span >Daily: 8am-4pm</span>
        </div>
        <div className="">
          <h3 className='font-bold text-lg pt-4 lg:pb-4'>CONTACT:</h3>
          <span>(510) 527-2326</span>
        </div>
      </section>
    </footer>
  )
}

export default Footer;