import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

const Drinks = () => {
  const data = useStaticQuery(graphql`
  query {
    allContentfulMenuItem(
      filter: {
        category: {
          title: {eq: "Hot Drinks"}
        }
      }
      sort: { fields: title, order: ASC }

    ) {
      edges {
        node {
          title
          id
          price
          category{
            title
          }
        }
      }
    }
  }
  `)

  const items = data.allContentfulMenuItem.edges;
  return (
    <div className='lg:w-3/4 w-full'>
      <p className="italic">Soy / Oat / Almond milk available</p>
      <ul className="my-2 grid grid-cols-2 gap-4">
        {
          items.map(({ node }) => {
            return (
              <li key={node.id} className="">
                <h3 className='font-bold text-lg'>{node.title}</h3>
                <span className=''>${node.price}</span>
              </li>
            )
          })
        }
      </ul>
    </div>
  )
}

export default Drinks;