import React, { useState } from 'react'
import Retail from './retail'
import Drinks from './drinks'
// import { useSpring, animated } from 'react-spring';


const Menu = () => {
  const [menuCategory, setMenuCategory] = useState('Drinks');
  let SelectedCategory;
  if (menuCategory === 'Drinks' ) {
    SelectedCategory = Drinks;
 
  } else if (menuCategory === 'Retail') {
    SelectedCategory = Retail
  }
  
  // const animationProps = useSpring({
  //   opacity: 1,
  //   from: { opacity: 0 }
  // });

  return (
    <section className="lg:w-1/2 flex flex-col josefin text-gray-900 lg:mb-0 mb-8">
      <h2 className='text-4xl font-bold'>OUR MENU</h2>
      <ul className="flex inline-flex text-lg mb-4 underline baseline">
        <a className={`${menuCategory === 'Drinks' ? `font-extrabold highlight` : `false`} px-1  mr-6 cursor-pointer` } onClick={() => setMenuCategory('Drinks')}>Drinks</a>
        <a className={`${menuCategory === 'Retail' ? `font-bold highlight` : `false`} px-1 cursor-pointer` } onClick={() => setMenuCategory('Retail')} >Retail</a>
      </ul>
      {/* <animated.div style={animationProps}> */}
      <SelectedCategory/>

      {/* </animated.div> */}
    </section>
  )
}

export default Menu;